<template>
  <vue-perfect-scrollbar
    :settings="{
      suppressScrollX: true,
      suppressScrollY: false,
      wheelPropagation: false,
    }"
    class="h-100 rtl-ps-none ps"
    style="height: 100%"
  >
    <!-- <div class="relative">
      <v-app-bar class="pt-1">
        <div
          class="d-flex justify-space-between align-center w-full"
          style="max-width: 100%"
        >
          <h6 class="ma-0">Video Llamada</h6>
          <slot name="notificationDrawerCloseButton"></slot>
        </div>
      </v-app-bar>
    </div> -->

    <div style="height: 100%">
      <!-- <div class="heading-label pa-0 mb-8">Notifications</div> -->

      <div style="height: 100%">
        <div
          class="d-flex justify-space-between"
          style="height: 100%; width: 100%"
        >
          <div class="d-flex" style="height: 100%; width: 100%">
            <iframe
              id="inlineFrameExample"
              v-if="showVideo && url"
              title="Inline Frame Example"
              height="100%"
              width="100%"
              style="border: none"
              :src="url"
              allow="geolocation; microphone; camera; display-capture"
            >
            </iframe>
          </div>
        </div>
        <v-divider class="py-2"></v-divider>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
      showVideo: false,
      url: null,
    };
  },
  computed: {
    ...mapState({
      profileData: (state) => state.profileSilver.profileData,
      callData: (state) => state.loggedUserSilver.callData,
    }),
    userData() {
      if (!this.profileData) return "";
      return `${this.profileData.user?.firstName || ""} ${
        this.profileData.user?.lastName || ""
      }`;
    },
  },
  methods: {
    open(data) {
      this.showVideo = false;
      if (data.extraFields.Videollamada) {
        var str = data.extraFields.Videollamada;
        var patt = /<a[^>]*href=["']([^"']*)["']/g;
        let url = patt.exec(str);
        if (url.length > 0) {
          this.url =
            url[1] +
            "&silver=true&agent=" +
            this.userData +
            "&turn_code=" +
            this.callData.code;
        }
        this.showVideo = true;
      } else {
        this.showVideo = false;
      }
    },
    close() {
      this.showVideo = false;
    },
  },
};
</script>
